import React, { useState } from 'react'
import loadable from "@loadable/component";
import usePostHook from '../../customHooks/usePostHook';
import { useNavigate, useParams } from 'react-router-dom';
import APIS from '../../constant/Endpoint';

const QrCodeFormComponent = loadable(() => import("../../components/QrCodeEditor/PopupFilter"));

const PopForm = () => {
  const { id } = useParams();
  const [selected, setSelected] = useState("text");
  const navigate = useNavigate();
  console.log(id, "id");


  const handlechange = (e: any) => {
    e.target.value;
    setSelected(e.target.value);
  }

  // const onSubmit = (data: any) => {
  //   console.log(data);
  //   console.log(id, "id");
  // }

  const {
    isPostLoading,
    mutateAsync: createQrcode,
  } = usePostHook({
    queryKey: `qrcode`,
    navigateURL: null
  });

  const handleQrcode = async (data: any) => {
    const url = APIS.QRCODE
    console.log(data, "page")
    const formData = {
      name: data?.title,
      qr_code_type: data?.field,
      content: {
        text: data?.textContent,
        url: data?.urlContent,
        is_dynamic: data?.dynamicQr,
        address: data?.email,
        subject: data?.preSubject,
        message: data?.preMessage,
        phone: data?.phoneContent,
        phone_number: data?.phoneContent,
        phone_or_email: data?.faceContent,
        latitude: data?.locationLatiContent,
        longitude: data?.locationLongContent,
        password: data?.password,
        wifi_hidden: data?.wifiHidden,
        encryption: data?.encryption,
        name: data?.wifiName,
        coin: data?.coin,
        first_name: data?.fname,
        last_name: data?.lname,
        missing_translation: data?.missing,
        email: data?.email,
        company: data?.company,
        job_title: data?.jobTitle,
        birthday: data?.birthday,
        street_address: data?.streetAddress,
        city: data?.city,
        zip: data?.zip,
        region: data?.region,
        country: data?.country,
        note: data?.note,
        type: data?.type,
        paypal_email: data?.payEmail,
        product_title: data?.productTitle,
        currency_code: data?.currencyCode,
        price: data?.price,
        thank_url: data?.thankUrl,
        cancel_url: data?.cancelUrl,
        geo_location: data?.geoLocation,
        notes: data?.notes,
        start_date: data?.startsOn,
        end_date: data?.endsOn,
        time_zone: data?.countary,
      }
    }
    console.log(formData, 'formData');

    try {
      const res = await createQrcode({ url, formData })
      //@ts-ignore
      console.log(res, 'res');
      await navigate(`/qrcode/${res?.data?.id}`)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <QrCodeFormComponent
        selected={selected}
        handlechange={handlechange}
        // onSubmit={onSubmit}
        handleQrcode={handleQrcode}
      />
    </>
  )
}

export default PopForm


