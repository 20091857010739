import React, { useState } from 'react';
import loadable from "@loadable/component";
import usePostFormDataHook from '../../customHooks/useFormDataPostHook';
import APIS from '../../constant/Endpoint';
import useGetHook from '../../customHooks/useGetHook';
import { useParams } from 'react-router-dom';


const QrCodeEditorComponent = loadable(() => import("../../components/QrCodeEditor"));

const CodeEditor = () => {
  const { id } = useParams()
  const { isPostLoading, mutateAsync, postData } = usePostFormDataHook({ queryKey: ["media"], navigateURL: null })
  const handleUploadLogo = async (setVaule: any, e: any) => {
    const url = APIS.MEDIA
    let formData = new FormData()
    formData.append("file", e?.target.files?.[0])
    try {
      const res = await mutateAsync({ url, formData })
      setVaule("image", res?.data?.file)
    } catch (err) {
      console.log(err);
    }
  }

  const { data: qrFormData } = useGetHook({
    queryKey: ["qrcode"],
    url: `${APIS.QRCODE}${id}`,
    params: {
    }
  });

  console.log(qrFormData, 'qrFormData');

  const { mutateAsync: qrUpdate } = usePostFormDataHook({ queryKey: ["qrcode"], navigateURL: null })
  const handleUploadQR = async (setVaule: any, e: any) => {
    const url = `${APIS.QRCODE}${id}`
    const formData = {
    }
    try {
      const res = await qrUpdate({ url, formData })
      console.log(res, "resUpdate")
      // setVaule("image", res?.data?.file)
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      <QrCodeEditorComponent
        handleUploadLogo={handleUploadLogo}
        qrFormData={qrFormData}
      />
    </>
  )
}

export default CodeEditor