


const Dashboard = () => {

  return (
    <div>
      <p className="text-red-500">Dashboard</p>
    </div>
  );
};

export default Dashboard;
