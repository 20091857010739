import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../components/Dashboard";
import QrCodeEditor from "../pages/QrCodeEditor";
import QrCodeList from "../pages/QrCodeEditor/QrCodeList";
import PopForm from "../pages/QrCodeEditor/PopForm";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/qrcode"} element={<QrCodeList />} /> 
        <Route path={"/qrcode/:id"} element={<QrCodeEditor />} /> 
        <Route path={"/form"} element={<PopForm />} /> 
        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
