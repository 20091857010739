import React, { useState } from 'react'
import loadable from "@loadable/component";
import APIS from '../../constant/Endpoint';
import useGetHook from '../../customHooks/useGetHook';
import useDeleteHook from '../../customHooks/useDeleteHook';
import usePersistentState from "../../customHooks/usePersistentStateHook";
import { useParams } from 'react-router-dom';

const QrCodeListComponent = loadable(() => import("../../components/QrCodeEditor/QrCodeList"));

const QrCodeList = () => {
  const { id } = useParams(); 
  const [addPopup, setAddPopup] = usePersistentState("openAddPopup")

   const { data: qrData, } = useGetHook({
      queryKey: ["qrcode"],
      url: `${APIS.QRCODE}`,
      params: {
      }
    });

  console.log(qrData,"qrData");
  const { mutateAsync: deleteQrCodeListMutateAsync } = useDeleteHook({ queryKey:"qrcode" })

  const handleQrCodeListDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.QRCODE}${pk}/`
    try {
      const res = await deleteQrCodeListMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      <QrCodeListComponent
        //  qrData = {qrData}
        handleQrCodeListDelete={handleQrCodeListDelete}
        addPopup={addPopup}
        setAddPopup={setAddPopup}
      />
    </>
  )
}

export default QrCodeList




